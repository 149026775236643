import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

import { Form, Button, Input, Spin } from "antd";
import { PageHeader } from "antd";

import { profile, updateProfile } from "../actions/auth";

function PageProfile({ getData, REFRESH, data, updateData }) {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    if (data) {
      form.resetFields();
      form.setFieldsValue(data);
    }
  }, [REFRESH, data]);

  const loadData = async () => {
    setLoading(true);
    await getData();
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const onFinish = async (v) => {
    setDataLoading(true);
    await updateData(v);
    setDataLoading(false);
  };

  return (
    <div className="site-page-header-ghost-wrapper">
      <PageHeader
        title="Profile"
        className="site-page-header"
        style={{ padding: "16px 0px" }}
      ></PageHeader>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin />
        </div>
      ) : (
        <Form
          form={form}
          className="ant-advanced-search-form"
          onFinish={onFinish}
          requiredMark={false}
          layout="vertical"
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Enter name",
              },
            ]}
          >
            <Input placeholder="name" disabled />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: "Enter email",
              },
            ]}
          >
            <Input placeholder="email" disabled />
          </Form.Item>
          <PageHeader
            style={{ padding: "16px 0px" }}
            title="Reset Password"
            className="site-page-header"
          ></PageHeader>
          <Form.Item
            name="current_password"
            label="Current Password"
            rules={[
              {
                required: true,
                message: "Enter current password",
              },
            ]}
          >
            <Input placeholder="current password" type="password" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Enter password",
              },
              {
                min: 6,
                message: "Password must be at least 6 characters!",
              },
            ]}
          >
            <Input placeholder="password" type="password" />
          </Form.Item>
          <Form.Item
            name="confirm_password"
            label="Confirm Password"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords do not match!")
                  );
                },
              }),
            ]}
          >
            <Input placeholder="password" type="password" />
          </Form.Item>
          <Button loading={dataLoading} onClick={form.submit}>
            Reset Password
          </Button>
        </Form>
      )}
    </div>
  );
}
const mapStateToProps = (state) => ({
  REFRESH: state.levent.USER_PROFILE,
  data: state?.auth?.profileData ?? {},
});

const mapDispatchToProps = (dispatch) => ({
  getData: () => dispatch(profile()),
  updateData: (data) => dispatch(updateProfile(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageProfile);
