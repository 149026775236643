import fetch from "cross-fetch";
import { API_BASE_URL, PATH_BANNER, PATH_PRODUCT_CREATE } from "../config";
import {
  requestStart,
  requestFinish,
  getApi,
  postApi,
  updateApi,
} from "./common";

export const GET_BANNER = "GET_BANNER";
export const RCV_BANNER = "RCV_BANNER";

function recvData(json) {
  return {
    type: RCV_BANNER,
    data: json,
    receivedAt: Date.now(),
  };
}

export function fetchBanner(reqData) {
  console.log("fetch product called", reqData);

  return function (dispatch) {
    dispatch(requestStart());

    return fetch(`${API_BASE_URL}${PATH_BANNER}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`,
      },
    })
      .then(
        (response) => response.json(),

        (error) => {
          throw new Error("Whoops!");
        }
      )
      .then((json) => {
        dispatch(recvData(json));

        dispatch(requestFinish());

        dispatch({
          type: "NOTIFICATION_HIDE",
          data: {
            title: "",
            type: "success",
            body: "",
          },
        });
      })
      .catch((error) => {
        dispatch(requestFinish());
      });
  };
}

export function createBanner(reqData) {
  console.log("create product called", reqData);

  return async function (dispatch) {
    const data = await postApi(dispatch, {
      url: `${API_BASE_URL}${PATH_BANNER}`,
      body: reqData,
    });

    if (data != null) {
      dispatch({
        type: "NOTIFICATION_SHOW",
        data: {
          title: "Banner Created",
          type: "success",
          body: "",
        },
      });

      dispatch({
        type: "FIRE_EVENT",
        data: {
          event: "BANNER_CREATE",
        },
      });
    } else {
      dispatch({
        type: "NOTIFICATION_SHOW",
        data: {
          title: "Cannot create banner",
          type: "error",
          body: "",
        },
      });
    }
  };
}

export function updateBanner(productId, reqData) {
  console.log("update banner called");

  return function (dispatch) {
    dispatch(requestStart());

    return fetch(`${API_BASE_URL}${PATH_BANNER}/${productId}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`,
      },
      body: JSON.stringify(reqData),
    })
      .then(
        (response) => response.json(),

        (error) => {
          throw new Error("Whoops!");
        }
      )
      .then((json) => {
        console.log("banner updated", json);
        dispatch(requestFinish());

        dispatch({
          type: "NOTIFICATION_SHOW",
          data: {
            title: "Banner Deleted",
            type: "success",
            body: "",
          },
        });

        dispatch({
          type: "FIRE_EVENT",
          data: {
            event: "BANNER_REFRESH",
          },
        });
      })
      .catch((error) => {
        dispatch(requestFinish());
      });
  };
}
