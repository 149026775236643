import { API_BASE_URL } from "../config";

export const REQUEST_START = "API_REQUEST_START";
export function requestStart() {
  return {
    type: REQUEST_START,
    isLoading: true,
  };
}
export const REQUEST_FINISH = "API_REQUEST_FINISH";
export function requestFinish() {
  return {
    type: REQUEST_FINISH,
    isLoading: false,
  };
}

export const AUTH_COMPLETE = "AUTH_COMPLETE";
export function authComplete(data) {
  const isAuthSuccess = data.success;

  return {
    type: AUTH_COMPLETE,

    data: data,
    isAuth: isAuthSuccess,
    authErrorMessage: isAuthSuccess ? "" : data.err,
  };
}

export function authUser(data) {
  return function (dispatch) {
    dispatch(requestStart());

    return fetch(`${API_BASE_URL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`,
      },
      body: JSON.stringify(data),
    })
      .then(
        (response) => response.json(),

        (error) => console.log("An error occurred.", error)
      )
      .then((json) => {
        dispatch(requestFinish());
        dispatch(authComplete(json));
      });
  };
}

export async function getApi(dispatch, req) {
  dispatch(requestStart());

  try {
    const data = await fetch(`${req.url}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`,
      },
    });

    dispatch(requestFinish());

    if (data.status === 200) {
      return data.json();
    }
  } catch (error) {}

  return null;
}

export async function postApi(dispatch, req) {
  dispatch(requestStart());

  try {
    const data = await fetch(`${req.url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${localStorage.getItem("access_token")}`,
      },
      body: JSON.stringify(req.body),
    });

    dispatch(requestFinish());

    if (data && data.status == 200) {
      return data.json();
    } else if (data && data.status == 422) {
      dispatch({
        type: "NOTIFICATION_SHOW",
        data: {
          title: "Invalid data",
          type: "error",
          body: JSON.stringify(data.json()),
        },
      });

      return null;
    } else if (data && data.status == 500) {
      let error = await data.json();

      console.log(error);
      dispatch({
        type: "NOTIFICATION_SHOW",
        data: {
          title: "Unable to process",
          type: "error",
          body: error.message,
        },
      });

      return null;
    } else if (!data || data.status == 401) {
      dispatch({
        type: "AUTH_FAILED",
      });

      return data.json();
    }
  } catch (error) {}

  return null;
}

export async function updateApi(dispatch, req) {
  dispatch(requestStart());

  const data = await fetch(`${req.url}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`,
    },
    referrer: "",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(req.body),
  });

  dispatch(requestFinish());

  if (data.status == 200) {
    return data.json();
  }

  return null;
}

export async function deleteApi(dispatch, req) {
  dispatch(requestStart());

  const data = await fetch(`${req.url}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("access_token")}`,
    },
    referrer: "",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(req?.body),
  });

  dispatch(requestFinish());

  if (data.status == 200) {
    return data.json();
  }

  return null;
}

export const LOOKUP = "LOOKUP";

export function fetchLookup(data) {
  return async function (dispatch) {
    const data = await getApi(dispatch, {
      url: `${API_BASE_URL}api/lookup`,
    });
    // console.log("api called", data);

    if (data != null) {
      dispatch({
        type: LOOKUP,
        data: data,
        receivedAt: Date.now(),
      });
    }
  };
}
