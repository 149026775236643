import {
  requestStart,
  requestFinish,
  getApi,
  postApi,
  updateApi,
} from "./common";
import { API_BASE_URL, PATH_AUTH, PATH_AUTH_REFRESH } from "../config";

export function auth(reqData) {
  return async function (dispatch) {
    const data = await postApi(dispatch, {
      url: `${API_BASE_URL}${PATH_AUTH}`,
      body: reqData,
    });

    if (data != null && data.access_token) {
      dispatch({
        type: "AUTH_SUCCESS",
        data: data,
      });
    } else {
      dispatch({
        type: "NOTIFICATION_SHOW",
        data: {
          title: "Login failed",
          type: "error",
          body: data.detail,
        },
      });
    }
  };
}

export function refresh(reqData) {
  return async function (dispatch) {
    const data = await postApi(dispatch, {
      url: `${API_BASE_URL}${PATH_AUTH_REFRESH}`,
      body: reqData,
    });

    if (data != null && data.access_token) {
      dispatch({
        type: "AUTH_SUCCESS",
        data: data,
      });
    } else {
      dispatch({
        type: "NOTIFICATION_SHOW",
        data: {
          title: "Session Expired",
          type: "error",
          body: data?.detail,
        },
      });
    }
  };
}

export function profile(reqData) {
  return async function (dispatch) {
    const data = await getApi(dispatch, {
      url: `${API_BASE_URL}user/admin`,
    });

    if (data != null) {
      dispatch({
        type: "USER_PROFILE",
        data: data,
      });
    } else {
      dispatch({
        type: "NOTIFICATION_SHOW",
        data: {
          title: "Fetching data failed",
          type: "error",
          body: data.detail,
        },
      });
    }
  };
}

export function updateProfile(reqData) {
  console.log("update profile called", reqData);

  return async function (dispatch) {
    const data = await postApi(dispatch, {
      url: `${API_BASE_URL}user/admin`,
      body: reqData,
    });

    if (data != null) {
      dispatch({
        type: "NOTIFICATION_SHOW",
        data: {
          title: "Password reset successfully",
          type: "success",
          body: "",
        },
      });

      dispatch({
        type: "USER_PROFILE",
        data: data,
      });

      dispatch({
        type: "FIRE_EVENT",
        data: {
          event: "USER_PROFILE",
        },
      });
    }
  };
}
